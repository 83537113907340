<style scoped lang='scss'>
#speech_recognition{
  .souceTit{
    display: inline-block;
    font-size: 24px;
    margin:0 auto 24px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;
  }
  .VividOS_ul li{
    margin-bottom: 20px;
    font-size: 16px;
    padding-left: 20px;
    position: relative;
  }
  .VividOS_ul li span{
    width: 12px;
    height: 12px;
    background-color: #54bbff;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: calc(50% - 6px);
  }
  .main_introduce{
    padding: 0 40px;
    .title2{
      font-size: 24px;
    }
    .colorTit{
      margin: 20px 0;
      font-size: 16px;
    }
    .center-block{
      margin-top: 30px !important;
      margin-bottom: 20px !important;
    }
    .OS_sol_title{
      font-size: 24px;
      margin: 60px 0 30px;
    }    
    .souce_j{
      padding-top: 60px; 
      .souce_Item{
        box-sizing: border-box;
        width: 450px;
        padding: 10px;
        .souce_Item_tit{
          width: 100%;
          line-height: 42px;
          text-align: center;
          background-color: #2bb3d5;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .souce_Item_wz{
          font-size: 16px;
          line-height: 1.6em;
          font-weight: 400;
          margin: 1rem 0;
        }
      }
      .souce_ItemImg{
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 50%);
        border-radius: 12px;
        background-color: #fff;
        margin: 10px;
        padding: 10px;
        img{
          width: 410px;
        }
      }
    }
    .souce_f{
      padding-top: 60px;
      .souceTit2{
        margin-bottom: 56px;
      }
      .souce_f_ListImg{
        img{
          margin-bottom: 20px;
        }
      }
      img{
        width: 280px;
      }
      .souce_f_list{
        padding-top: 80px;
        .item_souce_f{
          margin-bottom: 70px;
          .VividOS_ul p,.VividOS_ul li{line-height: 26px;font-size: 18px;}
          .VividOS_ul li span{left: 0;top: 5px}
          img{margin: initial;width: 340px;}
        }
        
      }
      
    }
    .souce_y{
      .souceTit{
        margin-bottom: 40px;
      }
      .souce_y_item{
        padding: 0 10px;
        margin-bottom: 80px;
        .souce_y_item_t{
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
      img{
        width: 430px;
      }
      img.bgImg{
        width: 100%;
      }
    }

  }

}


</style>

<template>
  <div id="speech_recognition">
    <div class="width rullMain">
      <div class="main_introduce">   
        <div class="title textCenter weight">语音识别解决方案</div>
        <div class="title2">我司语音识别解决方案的利用领域</div>
        <div class="colorTit">我司语音识别解决方案是关键字识别型，聚焦于家电产品操作、玩具等利用领域。</div>
        <img class="center-block" src="@/assets/images/business/4/sol_voice_main.png" alt="">
        <div>
          <div class="OS_sol_title">我司语音识别解决方案的特征</div>
          <ul class="VividOS_ul">
            <li><span></span>语音识别技术的优势</li>
            <li><span></span>解决方案提供方法的优势</li>
            <li><span></span>开发环境的优越性</li>
          </ul>
        </div>
        <div class="souce_j">
          <div class="acea-row row-center">
            <div class="souceTit">语音识别技术的优势</div> 
          </div>
          <div class="acea-row row-between">
            <div class="souce_Item">
              <div class="souce_Item_tit">识别速度（高实时性）</div>
              <div class="souce_Item_wz">能够本地对应，语音识别引擎和数据库配合硬件进行了优化。响应时间实现在500ms以内！</div>
              <div class="souce_Item_wz">搭载RTOS，从电源接通到语音识别都可以在1s以内实现！</div>
            </div>
            <div class="souce_Item souce_ItemImg">
              <img src="@/assets/images/business/4/sol_voice_02.png" alt="" srcset="">
            </div>
          </div>
          <div class="acea-row row-between">
            <div class="souce_Item souce_ItemImg">
              <img src="@/assets/images/business/4/2@3x-768x416.png" alt="" srcset="">
            </div>
            <div class="souce_Item">
              <div class="souce_Item_tit">识别速度（高实时性）</div>
              <div class="souce_Item_wz">我司的语音识别模块根据Mic的性能、种类配备了独自的降噪技术、光束成形技术。在噪音环境中可以保证95%以上的识别率！</div>
            </div>
          </div>
        </div>
        <div class="souce_f">
          <div class="acea-row row-center">
            <div class="souceTit">解决方案提供方法的优势</div> 
          </div>
          <div class="souceTit2 textCenter">我司语音识别解决方案阵容</div>
          <div class="souce_f_ListImg">
            <div class="acea-row row-between-wrapper">
              <img src="@/assets/images/business/4/5@3x-768x780.png" alt="">
              <img src="@/assets/images/business/4/6@3x-768x780.png" alt="">
              <img src="@/assets/images/business/4/7@3x-768x783.png" alt="">
            </div>
            <div class="acea-row row-between-wrapper">
              <img src="@/assets/images/business/4/23@3x-768x653.png" alt="">
              <img src="@/assets/images/business/4/24@3x-768x636.png" alt="">
              <img src="@/assets/images/business/4/25@3x-768x621.png" alt="">
            </div>
          </div>
          <div class="souce_f_list">
            <div class="item_souce_f">
              <div class="item_f_tit souceTit">低端机型</div>
              <div class="acea-row row-between">
                <ul class="VividOS_ul">
                  <li>
                    <span></span>
                    <p class="weight">超低功耗、低成本</p>
                    <p>采用超低功耗MCU</p>
                    <p>支持针对我司语音识别的RTOS</p>
                  </li>
                  <li>
                    <span></span>
                    <p class="weight">容易追加到客户现有的解决方案中</p>
                    <p>可通过UART通信I/F连接现有的主板</p>
                  </li>
                </ul>
                <img src="@/assets/images/business/4/5@3x-768x780.png" alt="">
              </div>
            </div>
            <div class="item_souce_f">
              <div class="item_f_tit souceTit">中端机型</div>
              <div class="acea-row row-between">                
                <ul class="VividOS_ul">
                  <li class="weight"><span></span>取得了成本和性能的平衡</li>
                  <li>
                    <span></span>                  
                    <p class="weight underline">同时支持Linux/vivid-RTOS</p>
                    <p>容易使用到现在为止的应用程序</p>
                  </li>
                  <li class="weight"><span></span>对应更丰富的关键词</li>
                  <li class="weight"><span></span>可单模块输出Speaker</li>
                  <li class="weight"><span></span>模块可以单独进行声音合成功能</li>
                  <li>  
                    <span></span>                  
                    <p class="weight">模块单体产品的其他控制</p>
                    <p class="weight">（PWM、GPIO、SPI、I2Cetc）是可能的</p>
                  </li>
                </ul>                
                <img src="@/assets/images/business/4/6@3x-768x780.png" alt="">
              </div>
            </div>
            <div class="item_souce_f">
              <div class="item_f_tit souceTit">高端机型</div>
              <div class="acea-row row-between">              
                <ul class="VividOS_ul">
                  <li class="weight"><span></span>比中端机型的功能更大</li>
                  <li class="weight"><span></span>LCD面板搭载、触摸屏搭载</li>
                  <li>
                    <span></span>
                    <p class="weight underline">通过Vivid-Linux提供GUI开发环境</p>
                    <p>支持GPU硬件加速</p>
                  </li>
                </ul>                
                <img src="@/assets/images/business/4/7@3x-768x783.png" alt="">
              </div>
            </div>        
          </div>
        </div>
        
        <div class="souce_y">
          <div class="acea-row row-center">
            <div class="souceTit">开发环境的优越性</div>
          </div>          
          <div class="acea-row row-between-wrapper">
            <div class="souce_y_item">
              <div class="souce_y_item_t textCenter weight">以往Linux开发环境的示意图</div>
              <div>
                <img src="@/assets/images/business/4/30@3x-1536x1094.png" alt="">
              </div>
            </div>
            <div class="souce_y_item">
              <div class="souce_y_item_t textCenter weight">使用我司VividOS开发环境的示意图</div>
              <div>
                <img src="@/assets/images/business/4/31@3x-1536x1094.png" alt="">
              </div>
            </div>
          </div>
          <div class="acea-row row-center">
            <div class="souceTit">从开发开始时起，我司就预先提供产品的基础程序</div>
          </div> 
          <img class="bgImg" src="@/assets/images/business/4/32@3x-1536x1402.png" alt="">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "speech_recognition",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
